@import '~antd/dist/antd.css';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';

html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0 !important;
}

.max-line-h {
    line-height: initial;
}
