.Dashboard {
    height: calc(100vh - 4%);
    width: calc(100vw - 2%);
    display: grid;
    margin: 0.5% auto;
    grid-template-areas:
        'title      title          title            title'
        'members    roomBookings   roomBookings     campusEvents'
        'members    keyContacts    keyContacts      campusEvents'
        'projects   projects       importantNotices importantNotices';
    grid-template-rows: 6% 40% 21% 33%;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    @media (min-width: 2050px) {
        gap: 10px;
    }

    & > section {
        height: 100%;
        width: 100%;
        display: flex;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    & > section:not(.TitleBar) {
        flex-direction: column;
    }
}

.TitleBar {
    grid-area: title;
    display: flex;
    padding: 0.2% 1%;
    align-items: center;
    justify-content: center;
    background-color: #ff323c;

    & > * {
        flex: 1;
    }

    &__Date {
        color: white;
        text-align: left;
    }
    &__Title {
        text-align: center;
        color: white;
    }
    &__Toggle {
        text-align: right;
    }

    @media (min-width: 2050px) {
        padding: 0.5% 1%;
    }
}

.Members {
    grid-area: members;
}

.CampusCalendar {
    grid-area: roomBookings;
    background-color: red !important;
}

.DarkModeToggle {
    grid-area: darkModeToggle;
    background-color: purple !important;
}

.CampusEvents {
    grid-area: campusEvents;
    background-color: yellow !important;
}

.ImportantNotices {
    grid-area: importantNotices;
    background-color: lightgray !important;
}

.KeyContacts {
    grid-area: keyContacts;
    background-color: magenta !important;
}

.Projects {
    grid-area: projects;
    background-color: blue !important;
}
